<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "FourOhFour",

  metaInfo: { title: "Oops! No Page Found" },

  extends: View,

  mixins: [
    LoadSections(["sections/404", "sections/contact-us", "sections/info"]),
  ],

  props: {
    id: {
      type: String,
      default: "about",
    },
  },
};
</script>
